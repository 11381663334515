import React, { useState, useRef, useEffect } from 'react';

import AxiosRequest from '../apis';

// 镜片价格表的request请求

const lensPriceApis = ['lens-price/v1/lenspricelist', 'lens-price/v1/lensbrands'];

const LensPriceRequestGet = ({ requestCount, reqParams, responseDataHandle }) => {
  // 镜片价格请求 Get
  const api = lensPriceApis[0];
  const method = 'get';
  const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
LensPriceRequestGet.defaultProps = {
  requestCount: 0,
  reqParams: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
};

const LensBrandsRequestGet = ({ requestCount, reqParams, responseDataHandle }) => {
  // 镜片品牌列表 Get
  const api = lensPriceApis[1];
  const method = 'get';
  const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
}
LensBrandsRequestGet.defaultProps = {
  requestCount: 0,
  reqParams: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
};

export {
  LensPriceRequestGet,
  LensBrandsRequestGet
};

import React from "react";

import NoFooterSiteLayout from '../components/site_layout/no_footer_layout';
import SEO from "../components/seo";

import LensPriceList from '../components/lens_price';

const LensPriceListPage = () => (
  <NoFooterSiteLayout>
    <SEO title="LensPriceList" />
    <LensPriceList></LensPriceList>
  </NoFooterSiteLayout>
)

export default LensPriceListPage;

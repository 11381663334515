import React, { useRef, useState, useEffect } from 'react';

import ProductsList from '../base_components/products_list';
import {
  LensPriceRequestGet,
  LensBrandsRequestGet
} from './lens_price_request';

// 镜片价格表

// 产品列表测试
// const testLensList = (initList = []) => {
//   const lensTestObj = {
//     image_cos_name1: 'https://purchase-records-1254307677.cos.ap-chengdu.myqcloud.com/customer-purchase/bpmg0_1594352079.jpeg',
//     len_title: `test`,
//     effects: '防蓝光',
//     len_price: '180',
//     len_sale_price: '160'
//   };

//   var retList = [].concat(initList);
//   let startNum = retList.length;
//   let endNum = startNum + 20
//   for (let index = startNum; index < endNum; index++) {
//     let curObj = { ...lensTestObj, len_title: `test${index}` };
//     retList.push(curObj);
//   }
//   console.log(retList);
//   return retList;
// }

const LensPriceList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const hasMore = useRef(true);
  const [lensPriceRequestCount, setLensPriceRequestCount] = useState(0);
  const lensPriceReqParams = useRef({});

  const [lensPriceLists, setLensPriceLists] = useState([]);
  const [lensBrandLists, setLensBrandLists] = useState([]);

  useEffect(() => {
    let lenstestData = []
    for (let index = 0; index < 10; index++) {
      const lensTestObj = {
        image_cos_name1: 'https://purchase-records-1254307677.cos.ap-chengdu.myqcloud.com/customer-purchase/bpmg0_1594352079.jpeg',
        len_title: `init test${index}`,
        effects: '防蓝光',
        len_price: '180',
        len_sale_price: '160'
      };
      lenstestData.push(lensTestObj);
    }
    console.log(lenstestData);
    setLensPriceLists(lenstestData);
  }, []);

  const loadMoreLensData = () => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (isLoading && !hasMore.current) {
      console.log('All loaded...')
      return;
    }
    setIsLoading(true);
    console.log('reach end');

    setLensPriceRequestCount(lensPriceRequestCount + 1);
  };

  const judgeHasMoreData = (respObj) => {
    // 判断是否还有数据
    if (Object.keys(respObj).includes('code')
      && respObj.code === 1000
      && Object.keys(respObj).includes('next')
      && typeof (respObj.next) === 'string'
      && respObj.next.length > 5
      && Object.keys(respObj).includes('results')) {
      return true;
    }
    return false;
  }

  const lensPriceRespDataHandle = (respObj) => {
    // 获取镜片数据后操作
    console.log('获取数据:', respObj);
    setIsLoading(false);
    hasMore.current = judgeHasMoreData(respObj);
    if (judgeHasMoreData(respObj)) {
      setLensPriceLists(lensPriceLists.concat(respObj.results))
    }

    // 测试数据
    // let loadedData = testLensList(lensPriceLists);
    // console.log(loadedData.length);
    // setLensPriceLists(loadedData);
    // if (loadedData.length > 200) {
    //   hasMore.current = false;
    //   console.log('Has more: ', hasMore.current);
    // }
  }

  return (
    <div>
      <ProductsList
        loading={isLoading} hasMore={hasMore.current}
        productsData={lensPriceLists}
        loadMore={loadMoreLensData}></ProductsList>

      <LensPriceRequestGet
        requestCount={lensPriceRequestCount}
        reqParams={lensPriceReqParams.current}
        responseDataHandle={lensPriceRespDataHandle}></LensPriceRequestGet>
    </div>
  );
}

export default LensPriceList;
